import React, { useEffect, useState } from 'react';
import {
    Box, FormLabel, Stack, Button, Heading, Input, useToast, Spinner,
    Text, HStack, VStack, IconButton, Flex, List, ListItem, 
    Center, Tabs, TabList, TabPanels, Tab, TabPanel, Textarea,
    Checkbox, CheckboxGroup, Collapse, Switch, Badge
} from '@chakra-ui/react';
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import axios from 'axios';

function Agents() {

    const [agents, setAgents] = useState([]);
    const [agent, setAgent] = useState(null);
    const toast = useToast();
    const [loading, setLoading] = useState(true);

    const [formData, setFormData] = useState();
    const [expandedAgent, setExpandedAgent] = useState(null);


    const createAgent = () => {
        setFormData({
            tasks: '',
            active: false,
            prompt: '',
            identifier: '',
            name: '',
            mode: 'create',
            transfers: [],
            summary: ''
        });
    }

    useEffect(() => {
        if (agent) {
            setFormData({
                tasks: agent.tasks || '',
                active: agent.active || false,
                prompt: agent.prompt || '',
                identifier: agent.identifier || '',
                name: agent.name || '',
                id: agent._id,
                mode: 'update',
                transfers: agent.transfers || [],
                summary: agent.summary || ''
            });
        }
    }, [agent]);

    const handleChange = (e) => {
        const { id, value, type, checked } = e.target;
        if (id === 'summary' && value.length > 200) {
            return;
        }
        setFormData((prevData) => ({
            ...prevData,
            [id]: type === 'checkbox' ? checked : value,
        }));
    };

    const handleTransferChange = (value) => {
        setFormData((prevData) => ({
            ...prevData,
            transfers: value,
        }));
    };

    const bringAgents = async () => {
        setLoading(true);
        axios.get('/virtual-agents')
            .then((response) => {
                setAgents(response.data);
                setLoading(false);
            })
            .catch((error) => {
                console.error(error);
                setLoading(false);
            });
    };

    useEffect(async () => {
        await bringAgents();
    }, []);

    const handleSubmit = async () => {
        try {
            switch (formData.mode) {
                case 'create':
                    await axios.post('/virtual-agents/', formData);
                    onClose();
                    toast({
                        title: 'Agente creado',
                        description: "Agente creado con éxito",
                        status: 'success',
                        duration: 9000,
                        isClosable: true,
                    })
                    break;
                case 'update':
                    await axios.put('/virtual-agents/' + formData.id, formData);
                    onClose();
                    toast({
                        title: 'Agente actualizado',
                        description: "Actualizamos los datos del agente " + formData.identifier,
                        status: 'success',
                        duration: 9000,
                        isClosable: true,
                    })
                    break;
            }
        } catch (error) {
            toast({
                title: 'Error',
                description: "Hubo un error al actualizar al agente " + formData.identifier,
                status: 'warning',
                duration: 9000,
                isClosable: true,
            })
        } finally {
            await bringAgents();
        }
    };

    const toggleExpand = (agentId) => {
        setExpandedAgent(expandedAgent === agentId ? null : agentId);
    };

    return (
        <HStack spacing={0} h="calc(100vh - 100px)" align="stretch" position="relative">
            {/* Agents List Sidebar */}
            <Box w="25%" borderRight="1px" borderColor="gray.200" p={2} fontSize="xs">
                <Flex justify="space-between" align="center" mb={2}>
                    <Heading size="xs" fontSize="sm">Agentes</Heading>
                    <IconButton
                        icon={<Text fontSize="xs" color="white">➕</Text>}
                        size="xs"
                        colorScheme="blue"
                        onClick={createAgent}
                        aria-label="Nuevo agente"
                    />
                </Flex>
                
                <Box overflow="auto" h="calc(100vh - 180px)">
                    {loading ? (
                        <Spinner size="sm" />
                    ) : (
                        <List spacing={1}>
                            {agents.map((ag) => (
                                <ListItem
                                    key={ag._id}
                                    p={1.5}
                                    cursor="pointer"
                                    bg={formData?.id === ag._id ? 'blue.50' : 'transparent'}
                                    _hover={{ bg: 'gray.50' }}
                                    borderRadius="md"
                                    onClick={() => setAgent(ag)}
                                    fontSize="sm"
                                >
                                    <Flex align="center" justify="space-between">
                                        <Text>🤖 {ag.name}</Text>
                                        <Badge colorScheme={ag.active ? 'green' : 'gray'} fontSize="xs">
                                            {ag.active ? 'Activo' : 'Inactivo'}
                                        </Badge>
                                    </Flex>
                                </ListItem>
                            ))}
                        </List>
                    )}
                </Box>
            </Box>

            {/* Detail Panel */}
            <Box w="75%" p={2} fontSize="2xs">
                {formData ? (
                    <VStack h="100%" spacing={2} align="stretch">
                        <Flex justify="space-between" align="center" shrink={0}>
                            <Input
                                id="name"
                                value={formData.name}
                                onChange={handleChange}
                                size="xs"
                                fontSize="sm"
                                fontWeight="medium"
                                width="auto"
                                height="24px"
                                placeholder="Nombre del agente"
                            />
                            <Switch 
                                id='active' 
                                isChecked={formData.active ?? false} 
                                onChange={handleChange}
                                size="sm"
                            />
                        </Flex>

                        <Tabs display="flex" flexDirection="column" flex={1} overflow="hidden" size="xs">
                            <TabList height="24px">
                                <Tab fontSize="2xs" py={1} px={2}>General</Tab>
                                <Tab fontSize="2xs" py={1} px={2}>Prompt</Tab>
                                <Tab fontSize="2xs" py={1} px={2}>Transferencias</Tab>
                            </TabList>

                            <TabPanels flex={1} overflow="auto">
                                <TabPanel h="100%" p={1}>
                                    <VStack spacing={2} align="stretch">
                                        <Box>
                                            <FormLabel fontSize="2xs" mb={0}>Identificador</FormLabel>
                                            <Input
                                                id="identifier"
                                                value={formData.identifier}
                                                onChange={handleChange}
                                                size="xs"
                                            />
                                        </Box>
                                        <Box>
                                            <FormLabel fontSize="2xs" mb={0}>Resumen</FormLabel>
                                            <Textarea
                                                id="summary"
                                                value={formData.summary}
                                                onChange={handleChange}
                                                size="xs"
                                                rows={3}
                                                maxLength={200}
                                            />
                                            <Text fontSize="2xs" textAlign="right">
                                                {formData.summary.length}/200
                                            </Text>
                                        </Box>
                                        <Box>
                                            <FormLabel fontSize="2xs" mb={0}>Tareas</FormLabel>
                                            <Textarea
                                                id="tasks"
                                                value={formData.tasks}
                                                onChange={handleChange}
                                                size="xs"
                                                rows={6}
                                            />
                                        </Box>
                                    </VStack>
                                </TabPanel>
                                <TabPanel h="100%" p={1}>
                                    <Textarea
                                        id="prompt"
                                        value={formData.prompt}
                                        onChange={handleChange}
                                        size="xs"
                                        h="100%"
                                    />
                                </TabPanel>
                                <TabPanel h="100%" p={1}>
                                    <CheckboxGroup
                                        value={formData.transfers}
                                        onChange={handleTransferChange}
                                    >
                                        <Stack spacing={2}>
                                            {agents.map((ag) => (
                                                ag.active && ag._id !== formData.id && (
                                                    <Box key={ag._id} p={2} borderRadius="md" _hover={{ bg: 'gray.50' }}>
                                                        <Flex align="center">
                                                            <Checkbox value={ag._id} />
                                                            <Text fontSize="xs" ml={2}>
                                                                {ag.name}
                                                            </Text>
                                                            <IconButton
                                                                icon={expandedAgent === ag._id ? <FaChevronUp /> : <FaChevronDown />}
                                                                onClick={() => toggleExpand(ag._id)}
                                                                variant="ghost"
                                                                size="xs"
                                                                marginLeft={2}
                                                            />
                                                        </Flex>
                                                        <Collapse in={expandedAgent === ag._id}>
                                                            <Text margin={"8px 0 0 24px"} fontStyle={"italic"} fontSize="2xs">
                                                                {ag.summary}
                                                            </Text>
                                                        </Collapse>
                                                    </Box>
                                                )
                                            ))}
                                        </Stack>
                                    </CheckboxGroup>
                                </TabPanel>
                            </TabPanels>
                        </Tabs>

                        <Button 
                            size="xs" 
                            colorScheme="blue" 
                            onClick={handleSubmit}
                            shrink={0}
                            fontSize="sm"
                            h="24px"
                        >
                            Guardar Cambios
                        </Button>
                    </VStack>
                ) : (
                    <Center h="100%">
                        <Text color="gray.500" fontSize="xs">Selecciona un agente para editar</Text>
                    </Center>
                )}
            </Box>
        </HStack>
    );
}

export default Agents;