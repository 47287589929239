import React from 'react';
import ReactDOM from 'react-dom';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { ChakraProvider } from '@chakra-ui/react';  // Agregar este import
import App from './App';

const msalConfig = {
    auth: {
        clientId: "59573f4e-5b26-42fc-8ffa-7bb4eef3cdd0",
        authority: "https://login.microsoftonline.com/19a1a8ec-9420-43a3-9403-9833a10ce204",
        redirectUri: window.location.origin,
    },
    cache: {
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: false,
    },
    system: {
        loggerOptions: {
            loggerCallback: (level, message) => {
                console.log(message);
            },
            logLevel: "Info"
        }
    }
};

const msalInstance = new PublicClientApplication(msalConfig);

// Eliminar el await ya que puede causar problemas en el contexto global
msalInstance.initialize();

ReactDOM.render(
    <React.StrictMode>
        <ChakraProvider>
            <MsalProvider instance={msalInstance}>
                <App />
            </MsalProvider>
        </ChakraProvider>
    </React.StrictMode>,
    document.getElementById('root')
);