import React, { useEffect, useState } from 'react';
import ChatApp from './components/ChatApp';
import { useMsal, AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import AzureLogin from './components/AzureLogin';
import axios from 'axios';

function App() {
    const { instance, accounts, inProgress } = useMsal();  // Agregamos inProgress
    const [profileImage, setProfileImage] = useState('');
    const [userName, setUserName] = useState('');
    const [isInitialized, setIsInitialized] = useState(false);
    const [userEmail, setUserEmail] = useState('');
    const [userStatus, setUserStatus] = useState('');
    const [userRoles, setUserRoles] = useState([]);

    const graphScopes = [
        "User.Read",    // Este es el scope básico que generalmente no requiere consentimiento admin
        "profile",
        "openid",
        "email"
    ];  // Removemos User.ReadBasic.All que requiere consentimiento admin

    const registerUserInBackend = async (userData, apiToken) => {
        try {
            const { data } = await axios.post('/user/login', {
                oid: userData.id,
                name: userData.displayName,
                email: userData.userPrincipalName
            }, {
                headers: {
                    'Authorization': `Bearer ${apiToken}`
                }
            });
            
            setUserEmail(userData.userPrincipalName);
            setUserStatus(data.status);
            setUserRoles(data.roles); // Guardar los roles del usuario
            return data;
        } catch (error) {
            console.error('Error registering user:', error);
        }
    };

    const getApiToken = async (account) => {
        try {
            return await instance.acquireTokenSilent({
                scopes: ["api://59573f4e-5b26-42fc-8ffa-7bb4eef3cdd0/read"],
                account: account,
            });
        } catch (error) {
            return await instance.acquireTokenPopup({
                scopes: ["api://59573f4e-5b26-42fc-8ffa-7bb4eef3cdd0/read"],
                account: account,
            });
        }
    };

    const handleStatusChange = async (newStatus) => {
        try {
            const token = await getApiToken(accounts[0]);
            await axios.put('/user/status', {
                email: userEmail,
                status: newStatus
            }, {
                headers: {
                    'Authorization': `Bearer ${token.accessToken}`
                }
            });
            setUserStatus(newStatus);
        } catch (error) {
            console.error('Error updating status:', error);
        }
    };

    const fetchUserData = async (account) => {
        try {
            // Obtener token para Microsoft Graph
            const graphResponse = await instance.acquireTokenSilent({
                scopes: graphScopes,
                account: account,
            });

            // Obtener token para tu API (comentado hasta que se necesite)
            /* const apiResponse = await instance.acquireTokenSilent({
                scopes: ["api://59573f4e-5b26-42fc-8ffa-7bb4eef3cdd0/read"],
                account: account,
            }); */

            // Usar el token de Graph para las llamadas a Microsoft Graph
            const { data: userData } = await axios.get('https://graph.microsoft.com/v1.0/me', {
                headers: {
                    'Authorization': `Bearer ${graphResponse.accessToken}`,
                    'Accept': 'application/json'
                }
            });

            console.log('Usuario datos:', userData); // Para debug
            setUserName(userData.displayName || userData.userPrincipalName);
            const apiToken = await getApiToken(account);
            await registerUserInBackend(userData, apiToken.accessToken);

            const photoResponse = await axios.get('https://graph.microsoft.com/v1.0/me/photo/$value', {
                headers: {
                    'Authorization': `Bearer ${graphResponse.accessToken}`
                },
                responseType: 'blob'
            });

            const reader = new FileReader();
            reader.onloadend = () => {
                setProfileImage(reader.result);
            };
            reader.readAsDataURL(photoResponse.data);

        } catch (error) {
            if (error.name === "InteractionRequiredAuthError") {
                // Si el token silencioso falla, intentar con popup
                await instance.acquireTokenPopup({
                    scopes: graphScopes,
                    account: account
                });
                // Reintentar fetchUserData
                await fetchUserData(account);
            } else {
                console.error('Error al obtener datos del usuario:', error);
            }
        }
    };

    useEffect(() => {
        const initializeAuth = async () => {
            try {
                // Esperar a que MSAL esté listo
                if (inProgress === "none" && accounts.length > 0 && !isInitialized) {
                    setIsInitialized(true);
                    await fetchUserData(accounts[0]);
                }
            } catch (error) {
                console.error('Error en la inicialización:', error);
            }
        };

        initializeAuth();
    }, [inProgress, accounts, isInitialized]);

    const handleLogin = async () => {
        try {
            if (inProgress === "none") {  // Solo intentar login si no hay otra operación en curso
                const loginResponse = await instance.loginPopup({
                    scopes: [...graphScopes, "api://59573f4e-5b26-42fc-8ffa-7bb4eef3cdd0/read"],
                });
                if (loginResponse) {
                    await fetchUserData(loginResponse.account);
                }
            }
        } catch (error) {
            console.error("Error en la autenticación:", error);
        }
    };

    return (
        <>
            <AuthenticatedTemplate>
                {profileImage && userName && ( // Asegurar que tenemos ambos datos
                    <>
                        <ChatApp 
                            userImage={profileImage} 
                            userName={userName}
                            userStatus={userStatus}
                            onStatusChange={handleStatusChange}
                            userRoles={userRoles}
                        />
                    </>
                )}
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <AzureLogin handleLogin={handleLogin} />
            </UnauthenticatedTemplate>
        </>
    );
}

export default App;