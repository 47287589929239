import React, { useEffect, useState } from 'react';
import {
  Box,
  FormLabel,
  Button,
  Heading,
  Input,
  useToast,
  Spinner,
  Tabs, TabList, TabPanels, Tab, TabPanel,
  HStack, VStack, IconButton, Flex,
  List, ListItem, Text,
  AlertDialog, AlertDialogBody, AlertDialogFooter,
  AlertDialogHeader, AlertDialogContent, AlertDialogOverlay,
  Center,
  Textarea,
} from '@chakra-ui/react';
import axios from 'axios';
import AceEditor from 'react-ace';

// Importar modos y temas necesarios para Ace Editor
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/mode-javascript';
import 'ace-builds/src-noconflict/theme-github';

function Tools() {
  const [tools, setTools] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedTool, setSelectedTool] = useState(null);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const cancelRef = React.useRef();
  const toast = useToast();

  const fetchTools = async () => {
    setLoading(true);
    try {
      const response = await axios.get('/tools');
      setTools(response.data);
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Error cargando herramientas',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTools();
  }, []);

  const handleSave = async (tool) => {
    try {
      // Ensure input_schema is properly parsed before sending
      const dataToSend = {
        ...tool,
        input_schema: typeof tool.input_schema === 'string' 
          ? JSON.parse(tool.input_schema)
          : tool.input_schema
      };
      
      await axios.put(`/tools/${tool._id}`, dataToSend);
      toast({
        title: 'Herramienta actualizada',
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
      fetchTools();
    } catch (error) {
      toast({
        title: 'Error',
        description: error.message,
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const handleChange = (toolId, field, value) => {
    setTools((prevTools) =>
      prevTools.map((tool) => {
        if (tool._id === toolId) {
          // Special handling for input_schema
          if (field === 'input_schema') {
            try {
              // Validate if it's valid JSON
              JSON.parse(value);
            } catch (e) {
              // If not valid JSON, still update but show error
              toast({
                title: 'Warning',
                description: 'Invalid JSON format',
                status: 'warning',
                duration: 3000,
                isClosable: true,
              });
            }
          }
          return { ...tool, [field]: value };
        }
        return tool;
      })
    );
  };

  const handleDelete = async (tool) => {
    try {
      await axios.delete(`/tools/${tool._id}`);
      toast({
        title: 'Herramienta eliminada',
        status: 'success',
        duration: 3000,
      });
      setSelectedTool(null);
      fetchTools();
    } catch (error) {
      toast({
        title: 'Error',
        description: error.message,
        status: 'error',
      });
    }
    setIsDeleteDialogOpen(false);
  };

  const createNewTool = async () => {
    try {
      const response = await axios.post('/tools', {
        name: 'Nueva herramienta',
        description: '',
        input_schema: '{}',
        functionBody: ''
      });
      fetchTools();
      setSelectedTool(response.data);
    } catch (error) {
      toast({
        title: 'Error',
        description: error.message,
        status: 'error',
      });
    }
  };

  return (
    <HStack spacing={0} h="calc(100vh - 100px)" align="stretch">
      {/* Lista de herramientas */}
      <Box w="25%" borderRight="1px" borderColor="gray.200" p={2} fontSize="xs">
        <Flex justify="space-between" align="center" mb={2}>
          <Heading size="xs" fontSize="sm">Herramientas</Heading>
          <IconButton
            icon={<Text fontSize="xs" color="white">➕</Text>}
            size="xs"
            colorScheme="blue"
            onClick={createNewTool}
            aria-label="Nueva herramienta"
          />
        </Flex>
        
        <Box overflow="auto" h="calc(100vh - 180px)">
          {loading ? (
            <Spinner size="sm" />
          ) : (
            <List spacing={1}>
              {tools.map((tool) => (
                <ListItem
                  key={tool._id}
                  p={1.5}
                  cursor="pointer"
                  bg={selectedTool?._id === tool._id ? 'blue.50' : 'transparent'}
                  _hover={{ bg: 'gray.50' }}
                  borderRadius="md"
                  onClick={() => setSelectedTool(tool)}
                  fontSize="sm"
                >
                  <Text>🛠️ {tool.name}</Text>
                </ListItem>
              ))}
            </List>
          )}
        </Box>
      </Box>

      {/* Panel de detalle */}
      <Box w="75%" p={2} fontSize="2xs">
        {selectedTool ? (
          <VStack h="100%" spacing={2} align="stretch">
            <Flex justify="space-between" align="center" shrink={0}>
              <Input
                value={selectedTool.name}
                onChange={(e) => handleChange(selectedTool._id, 'name', e.target.value)}
                size="xs"
                fontSize="sm"
                fontWeight="medium"
                width="auto"
                height="24px"
              />
              <IconButton
                icon={<Text fontSize="2xs" color="white">🗑️</Text>}
                size="xs"
                colorScheme="red"
                onClick={() => setIsDeleteDialogOpen(true)}
                aria-label="Eliminar herramienta"
              />
            </Flex>

            <Box shrink={0}>
              <FormLabel fontSize="2xs" mb={0}>Descripción</FormLabel>
              <Textarea
                value={selectedTool.description}
                onChange={(e) =>
                  handleChange(selectedTool._id, 'description', e.target.value)
                }
                rows={2}
                resize="none"
                size="xs"
                fontSize="xs"
                minH="40px"
                p={1}
              />
            </Box>

            <Tabs display="flex" flexDirection="column" flex={1} overflow="hidden" size="xs">
              <TabList height="24px">
                <Tab fontSize="2xs" py={1} px={2}>Definición</Tab>
                <Tab fontSize="2xs" py={1} px={2}>Función</Tab>
              </TabList>

              <TabPanels flex={1} overflow="auto">
                <TabPanel h="100%" p={1}>
                  <Box h="calc(100% - 24px)"> {/* Ajustado para considerar el label */}
                    <FormLabel fontSize="2xs" mb={0}>JSON Schema</FormLabel>
                    <Box h="calc(100% - 4px)"> {/* El editor ocupa todo el espacio restante */}
                      <AceEditor
                        mode="json"
                        theme="github"
                        value={typeof selectedTool.input_schema === 'string' 
                          ? selectedTool.input_schema 
                          : JSON.stringify(selectedTool.input_schema, null, 2)}
                        onChange={(value) =>
                          handleChange(selectedTool._id, 'input_schema', value)
                        }
                        width="100%"
                        height="100%"
                        name="jsonEditor"
                        fontSize={11}
                        showPrintMargin={false}
                      />
                    </Box>
                  </Box>
                </TabPanel>
                <TabPanel h="100%" p={1}>
                  <Box h="100%">
                    <AceEditor
                      mode="javascript"
                      theme="github"
                      value={selectedTool.functionBody || ''}
                      onChange={(value) =>
                        handleChange(selectedTool._id, 'functionBody', value)
                      }
                      width="100%"
                      height="100%"
                      name="functionEditor"
                      fontSize={11}
                      showPrintMargin={false}
                    />
                  </Box>
                </TabPanel>
              </TabPanels>
            </Tabs>

            <Button 
              size="xs" 
              colorScheme="blue" 
              onClick={() => handleSave(selectedTool)} 
              shrink={0}
              fontSize="sm"
              h="24px"
            >
              Guardar Cambios
            </Button>
          </VStack>
        ) : (
          <Center h="100%">
            <Text color="gray.500" fontSize="xs">Selecciona una herramienta para editar</Text>
          </Center>
        )}
      </Box>

      <AlertDialog
        isOpen={isDeleteDialogOpen}
        leastDestructiveRef={cancelRef}
        onClose={() => setIsDeleteDialogOpen(false)}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader>Eliminar Herramienta</AlertDialogHeader>
            <AlertDialogBody>
              ¿Estás seguro? Esta acción no se puede deshacer.
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={() => setIsDeleteDialogOpen(false)}>
                Cancelar
              </Button>
              <Button colorScheme="red" ml={3} onClick={() => handleDelete(selectedTool)}>
                Eliminar
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </HStack>
  );
}

export default Tools;