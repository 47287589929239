import React, { useEffect, useRef, useState } from 'react';
import ChatList from './ChatList';
import ChatWindow from './ChatWindow';
import io from 'socket.io-client';
import {
    Tabs,
    TabList,
    TabPanels,
    Tab,
    TabPanel,
    Flex,
    Icon,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    Button,
    Text,
    HStack,
    Box,
} from '@chakra-ui/react';
import { FaComments, FaRobot } from 'react-icons/fa';
import { FaGear } from "react-icons/fa6";
import { MdCampaign } from "react-icons/md";
import { FaSignOutAlt, FaCircle } from 'react-icons/fa';
import Agents from './Agents';
import ChatWidget from './ChatWidget';
import Procesito from './Procesito';
import Tools from './Tools';
import logoSegu from '../images/segulogo.png';

const ENV = window.location.origin;
const socket = io(ENV);

function ChatApp({ userImage, userName, userStatus, onStatusChange, userRoles }) {
    const [chats, setChats] = useState([]);
    const [selectedChat, setSelectedChat] = useState(null);
    const chatWindowRef = useRef(null);
    const [status, setStatus] = useState(userStatus || 'Disponible');

    // Agregar console.log para debug
    useEffect(() => {
        console.log('ChatApp props:', { userImage, userName, userStatus });
    }, [userImage, userName, userStatus]);

    useEffect(() => {
        // Emitir la solicitud de obtener todos los chats con sus mensajes
        socket.emit('get-chats');
        debugger;
        socket.on('chats', (receivedChats) => {
            setChats(receivedChats);
            if (receivedChats.length > 0 && !selectedChat) {
                setSelectedChat(receivedChats[0]); // Seleccionar el primer chat por defecto
                scrollToBottom();
            }
        })

        // Escuchar actualizaciones de chats
        socket.on('chatChange', (updatedChat) => {
            console.log("updatedChat > ", updatedChat);
            setChats((prevChats) => {
                const chatIndex = prevChats.findIndex((chat) => chat._id === updatedChat._id);
                if (chatIndex !== -1) {
                    const newChats = [...prevChats];
                    newChats[chatIndex] = updatedChat;
                    return newChats;
                } else {
                    return [...prevChats, updatedChat];
                }
            });

            // Actualizar el chat seleccionado si es el mismo que se actualizó
            if (selectedChat && selectedChat._id === updatedChat._id) {
                setSelectedChat(updatedChat);
                scrollToBottom();
            }
        });

        return () => {
            socket.off('chats');
            socket.off('chatChange');
        };
    }, [selectedChat]);

    const scrollToBottom = () => {
        if (chatWindowRef.current) {
            chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
        }
    };

    const handleStatusChange = (newStatus) => {
        setStatus(newStatus);
        // Here you would emit the status change to your backend
        socket.emit('update-user-status', newStatus);
        onStatusChange(newStatus);
    };

    const isDefaultUser = userRoles?.includes('Default');

    return (
        <>
            <Flex align="center" mt={4}>
                <Tabs flex="1">
                    <Flex width="100%" align="center">
                        <TabList flex="1">
                            <img src={logoSegu} alt="Segunext" height="40px" style={{ marginRight: '16px', marginLeft: '16px', height: '40px' }} />
                            <Tab>
                                <Icon as={FaComments} mr={2} />
                                Chats
                            </Tab>
                            {!isDefaultUser && (
                                <>
                                    <Tab>
                                        <Icon as={FaRobot} mr={2} />
                                        Agentes
                                    </Tab>
                                    <Tab>
                                        <Icon as={MdCampaign} mr={2} />
                                        Procesito
                                    </Tab>
                                    <Tab>
                                        <Icon as={FaGear} mr={2} />
                                        Herramientas
                                    </Tab>
                                </>
                            )}
                        </TabList>

                        <Box pr={4}>
                            <Menu>
                                <MenuButton
                                    as={Button}
                                    variant="ghost"
                                    rightIcon={<Icon as={FaCircle} color={status === 'Disponible' ? 'green.400' : 'orange.400'} w={3} h={3} />}
                                >
                                    <HStack spacing={3}>
                                        <img
                                            src={userImage}
                                            alt={userName}
                                            style={{
                                                height: '32px',
                                                width: '32px',
                                                borderRadius: '100%',
                                                objectFit: 'cover'
                                            }}
                                        />
                                        <Box textAlign="left">
                                            <Text fontSize="sm" fontWeight="medium">
                                                {userName || 'Usuario'}
                                            </Text>
                                            <Text fontSize="xs" color="gray.500" textTransform="capitalize">
                                                {status}
                                            </Text>
                                        </Box>
                                    </HStack>
                                </MenuButton>
                                <MenuList>
                                    <MenuItem
                                        icon={<FaCircle color="green" />}
                                        onClick={() => handleStatusChange('Disponible')}
                                        fontSize="sm"
                                    >
                                        Disponible
                                    </MenuItem>
                                    <MenuItem
                                        icon={<FaCircle color="orange" />}
                                        onClick={() => handleStatusChange('Ocupado')}
                                        fontSize="sm"
                                    >
                                        Ocupado
                                    </MenuItem>
                                    <MenuItem
                                        icon={<FaSignOutAlt />}
                                        onClick={() => window.location.reload()}
                                        fontSize="sm"
                                        color="red.500"
                                    >
                                        Cerrar Sesión
                                    </MenuItem>
                                </MenuList>
                            </Menu>
                        </Box>
                    </Flex>

                    <TabPanels>
                        <TabPanel>
                            <Flex height="80vh">
                                {chats.length > 0 && (
                                    <ChatList chats={chats} onSelectChat={setSelectedChat} />
                                )}
                                {selectedChat && (
                                    <ChatWindow ref={chatWindowRef} chat={selectedChat} />
                                )}
                            </Flex>
                        </TabPanel>
                        <TabPanel>
                            {!isDefaultUser && <Agents />}
                        </TabPanel>
                        <TabPanel>
                            {!isDefaultUser && <Procesito />}
                        </TabPanel>
                        <TabPanel>
                            {!isDefaultUser && <Tools />}
                        </TabPanel>

                    </TabPanels>
                </Tabs>
            </Flex>
            <>
                <ChatWidget />
            </>
        </>
    );
}

export default ChatApp;