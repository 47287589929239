import React, { useEffect, useState } from 'react';
import {
    Box, FormLabel, Button, Heading, Input, useToast, Spinner,
    Text, HStack, VStack, IconButton, Flex, List, ListItem, 
    Center, Tabs, TabList, TabPanels, Tab, TabPanel
} from '@chakra-ui/react';
import { FaPlay } from "react-icons/fa";
import axios from 'axios';
import { JSONTree } from 'react-json-tree';
import AceEditor from 'react-ace';

import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-github';

function Procesito() {
    const [processes, setProcesses] = useState([]);
    const [process, setProcess] = useState(null);
    const toast = useToast();
    const [loading, setLoading] = useState(true);
    const [formData, setFormData] = useState();

    // Test panel state
    const [isTestPanelOpen, setIsTestPanelOpen] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [testBody, setTestBody] = useState('');
    const [testResult, setTestResult] = useState('');
    const [testLoading, setTestLoading] = useState(false);
    const [selectedProcess, setSelectedProcess] = useState(null);

    const createProcess = () => {
        setFormData({
            name: '',
            body: '',
            prompt: '',
            jsonOutput: '',
            mode: 'create'
        });
    }

    const fetchProcesses = async () => {
        setLoading(true);
        try {
            const response = await axios.get('/process');
            setProcesses(response.data);
        } catch (error) {
            console.error(error);
            toast({
                title: 'Error',
                description: "Error al cargar los procesos",
                status: 'error',
                duration: 9000,
                isClosable: true,
            });
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (process) {
            setFormData({
                name: process.name || '',
                body: process.body || '',
                prompt: process.prompt || '',
                jsonOutput: process.jsonOutput || '',
                id: process._id,
                mode: 'update'
            });
        }
    }, [process]);

    const handleChange = (e) => {
        const { id, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [id]: value
        }));
    };

    const handleSubmit = async () => {
        try {
            switch (formData.mode) {
                case 'create':
                    await axios.post('/process', formData);
                    toast({
                        title: 'Proceso creado',
                        description: "Proceso creado con éxito",
                        status: 'success',
                        duration: 9000,
                        isClosable: true,
                    });
                    break;
                case 'update':
                    await axios.patch('/process/' + formData.id, formData);
                    toast({
                        title: 'Proceso actualizado',
                        description: "Proceso actualizado con éxito",
                        status: 'success',
                        duration: 9000,
                        isClosable: true,
                    });
                    break;
            }
        } catch (error) {
            toast({
                title: 'Error',
                description: "Hubo un error en la operación",
                status: 'error',
                duration: 9000,
                isClosable: true,
            });
        } finally {
            await fetchProcesses();
        }
    };

    const handleDelete = async (id) => {
        try {
            await axios.delete(`/process/${id}`);
            toast({
                title: 'Proceso eliminado',
                description: "Proceso eliminado con éxito",
                status: 'success',
                duration: 9000,
                isClosable: true,
            });
            await fetchProcesses();
        } catch (error) {
            toast({
                title: 'Error',
                description: "Error al eliminar el proceso",
                status: 'error',
                duration: 9000,
                isClosable: true,
            });
        }
    };

    const handleTest = async () => {
        if (!selectedFile || !testBody) {
            toast({
                title: 'Error',
                description: "Debe seleccionar un archivo y completar el body",
                status: 'warning',
                duration: 9000,
                isClosable: true,
            });
            return;
        }

        setTestLoading(true);
        try {
            const reader = new FileReader();
            reader.onload = async () => {
                const base64 = reader.result.split(',')[1];
                const testData = {
                    name: selectedProcess.name,
                    body: JSON.parse(testBody),
                    base64: base64,
                    mimeType: selectedFile.type
                };

                const response = await axios.post('/process/use', testData);
                setTestResult(JSON.stringify(response.data, null, 2));
            };
            reader.readAsDataURL(selectedFile);
        } catch (error) {
            setTestResult(JSON.stringify(error.response?.data || error.message, null, 2));
        } finally {
            setTestLoading(false);
        }
    };

    useEffect(() => {
        fetchProcesses();
    }, []);

    const openTestModal = (process) => {
        setSelectedProcess(process);
        setTestBody(process.body);
        setIsTestPanelOpen(true);
    };

    const closeTestPanel = () => {
        setIsTestPanelOpen(false);
        setSelectedFile(null);
        setTestBody('');
        setTestResult('');
    };

    return (
        <HStack spacing={0} h="calc(100vh - 100px)" align="stretch" position="relative">
            {/* Process List Sidebar */}
            <Box w="25%" borderRight="1px" borderColor="gray.200" p={2} fontSize="xs">
                <Flex justify="space-between" align="center" mb={2}>
                    <Heading size="xs" fontSize="sm">Procesos</Heading>
                    <IconButton
                        icon={<Text fontSize="xs" color="white">➕</Text>}
                        size="xs"
                        colorScheme="blue"
                        onClick={createProcess}
                        aria-label="Nuevo proceso"
                    />
                </Flex>
                
                <Box overflow="auto" h="calc(100vh - 180px)">
                    {loading ? (
                        <Spinner size="sm" />
                    ) : (
                        <List spacing={1}>
                            {processes.map((proc) => (
                                <ListItem
                                    key={proc._id}
                                    p={1.5}
                                    cursor="pointer"
                                    bg={formData?.id === proc._id ? 'blue.50' : 'transparent'}
                                    _hover={{ bg: 'gray.50' }}
                                    borderRadius="md"
                                    onClick={() => setProcess(proc)}
                                    fontSize="sm"
                                >
                                    <Text>📄 {proc.name}</Text>
                                </ListItem>
                            ))}
                        </List>
                    )}
                </Box>
            </Box>

            {/* Detail Panel */}
            <Box w="75%" p={2} fontSize="2xs">
                {formData ? (
                    <VStack h="100%" spacing={2} align="stretch">
                        <Flex justify="space-between" align="center" shrink={0}>
                            <Input
                                id="name"
                                value={formData.name}
                                onChange={handleChange}
                                size="xs"
                                fontSize="sm"
                                fontWeight="medium"
                                width="auto"
                                height="24px"
                            />
                            <HStack>
                                <IconButton
                                    icon={<FaPlay />}
                                    size="xs"
                                    colorScheme="green"
                                    onClick={() => openTestModal(formData)}
                                    aria-label="Probar proceso"
                                />
                                <IconButton
                                    icon={<Text fontSize="2xs" color="white">🗑️</Text>}
                                    size="xs"
                                    colorScheme="red"
                                    onClick={() => handleDelete(formData.id)}
                                    aria-label="Eliminar proceso"
                                />
                            </HStack>
                        </Flex>

                        <Tabs display="flex" flexDirection="column" flex={1} overflow="hidden" size="xs">
                            <TabList height="24px">
                                <Tab fontSize="2xs" py={1} px={2}>Body Schema</Tab>
                                <Tab fontSize="2xs" py={1} px={2}>Prompt</Tab>
                                <Tab fontSize="2xs" py={1} px={2}>Output Schema</Tab>
                            </TabList>

                            <TabPanels flex={1} overflow="auto">
                                <TabPanel h="100%" p={1}>
                                    <AceEditor
                                        mode="json"
                                        theme="github"
                                        value={formData.body}
                                        onChange={(value) => handleChange({ target: { id: 'body', value }})}
                                        width="100%"
                                        height="100%"
                                        fontSize={11}
                                        showPrintMargin={false}
                                        wrapEnabled={true}
                                    />
                                </TabPanel>
                                <TabPanel h="100%" p={1}>
                                    <AceEditor
                                        mode="text"
                                        theme="github"
                                        value={formData.prompt}
                                        onChange={(value) => handleChange({ target: { id: 'prompt', value }})}
                                        width="100%"
                                        height="100%"
                                        fontSize={11}
                                        showPrintMargin={false}
                                        wrapEnabled={true}
                                    />
                                </TabPanel>
                                <TabPanel h="100%" p={1}>
                                    <AceEditor
                                        mode="json"
                                        theme="github"
                                        value={formData.jsonOutput}
                                        onChange={(value) => handleChange({ target: { id: 'jsonOutput', value }})}
                                        width="100%"
                                        height="100%"
                                        fontSize={11}
                                        showPrintMargin={false}
                                        wrapEnabled={true}
                                    />
                                </TabPanel>
                            </TabPanels>
                        </Tabs>

                        <Button 
                            size="xs" 
                            colorScheme="blue" 
                            onClick={handleSubmit}
                            shrink={0}
                            fontSize="sm"
                            h="24px"
                        >
                            Guardar Cambios
                        </Button>
                    </VStack>
                ) : (
                    <Center h="100%">
                        <Text color="gray.500" fontSize="xs">Selecciona un proceso para editar</Text>
                    </Center>
                )}
            </Box>

            {/* Collapsible Test Panel */}
            <Box
                position="fixed"
                right={0}
                top="100px"
                h="calc(100vh - 100px)"
                w="400px"
                bg="white"
                borderLeft="1px solid"
                borderColor="gray.200"
                transform={`translateX(${isTestPanelOpen ? "0" : "100%"})`}
                transition="transform 0.3s"
                zIndex={2}
                shadow="lg"
            >
                <VStack h="100%" spacing={2} p={2}>
                    <Flex w="100%" justify="space-between" align="center">
                        <Heading size="xs" fontSize="sm">
                            Probar: {selectedProcess?.name}
                        </Heading>
                        <IconButton
                            icon={<Text fontSize="xs">✕</Text>}
                            size="xs"
                            variant="ghost"
                            onClick={closeTestPanel}
                        />
                    </Flex>

                    <VStack flex={1} w="100%" spacing={2} overflow="auto">
                        <Box w="100%">
                            <FormLabel fontSize="2xs" mb={0}>Archivo</FormLabel>
                            <Input
                                type="file"
                                onChange={(e) => setSelectedFile(e.target.files[0])}
                                size="xs"
                                fontSize="xs"
                                height="24px"
                            />
                        </Box>

                        <Box w="100%">
                            <FormLabel fontSize="2xs" mb={0}>Body (JSON)</FormLabel>
                            <AceEditor
                                mode="json"
                                theme="github"
                                value={testBody}
                                onChange={(value) => setTestBody(value)}
                                width="100%"
                                height="200px"
                                fontSize={11}
                                showPrintMargin={false}
                                wrapEnabled={true}
                            />
                        </Box>

                        <Box w="100%">
                            <FormLabel fontSize="2xs" mb={0}>Resultado</FormLabel>
                            <Box overflow="auto" h="100%" fontSize="11px">
                                {testResult && (
                                    <JSONTree 
                                        data={JSON.parse(testResult)}
                                        theme={{
                                            base00: '#fff',
                                            extend: {
                                                fontSize: '11px',
                                                lineHeight: '1.2'
                                            }
                                        }}
                                    />
                                )}
                            </Box>
                        </Box>
                    </VStack>

                    <Button
                        size="xs"
                        colorScheme="blue"
                        onClick={handleTest}
                        isLoading={testLoading}
                        loadingText="Procesando..."
                        fontSize="xs"
                        h="24px"
                    >
                        Probar
                    </Button>
                </VStack>
            </Box>
        </HStack>
    );
}

export default Procesito;